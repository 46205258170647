import request from '@/request'

export const getProductList = (data = {}) => {
  return request({
    url: '/home/get_product_list',
    method: 'get',
    params: data
  })
}

export const getProductInfo = (data = {}) => {
  return request({
    url: '/home/get_product_info',
    method: 'get',
    params: data
  })
}